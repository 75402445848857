// src/QrCodeGenerator.js

import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './QrCodeGenerator.css';

const QrCodeGenerator = () => {
  const [url, setUrl] = useState('');
  const [qrCodes, setQrCodes] = useState([]);

  const handleGenerate = () => {
    if (url) {
      setQrCodes([...qrCodes, url]);
      setUrl('');
    }
  };

  return (
    <div className="container">
      <h1>Enter URL to Generate QR Code</h1>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter URL"
        className="url-input"
      />
      <button onClick={handleGenerate} className="generate-button">Generate QR Code</button>

      <div className="qr-codes">
        {qrCodes.map((code, index) => (
          <div key={index} className="qr-code">
            <QRCodeSVG value={code} />
            <p>{code}</p>
          </div>
        ))}
      </div>

      <p className="count">Total QR Codes Generated: {qrCodes.length}</p>
    </div>
  );
};

export default QrCodeGenerator;
